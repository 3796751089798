/**
 * List videos by one tag
 */
import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import kebabCase from 'lodash/kebabCase'
import styled from 'styled-components'
import inflection from 'inflection'
import EggheadVideoList from '../components/EggheadVideoList'

const AllTagsLink = styled(Link)`
  color: ${props => props.theme.linkColor};
  font-size: 18px;
`

const ItemList = styled.ul`
  margin: 50px 0 0 0;
  padding: 0;
`

const Header = styled.h1`
  margin-bottom: 4px;
`

export default function VideoTags({ pageContext, data, location }) {
  const siteTitle = data.site.siteMetadata.title
  const { tag } = pageContext
  const { edges, totalCount } = data.allEgghead
  const tagHeader = `${totalCount} ${inflection.inflect(
    'video',
    totalCount
  )} tagged with "${tag}"`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`${kebabCase(tag)} articles`}
        keywords={[`videos`, `python`, `javascript`, `react`, `gatsby`]}
      />
      <Header>{tagHeader}</Header>
      <AllTagsLink to="videos/tags">View all tags</AllTagsLink>
      <EggheadVideoList videos={edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($tag: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allEgghead(
      sort: { fields: publishedAt, order: DESC }
      filter: { tags: { eq: $tag } }
    ) {
      edges {
        node {
          title
          url
          tags
        }
      }
      totalCount
    }
  }
`
